import moment from 'moment'
export default (duration = 0) => {
  var durationValue = moment.duration(duration, 'seconds')
  var hours = Math.floor(durationValue.asHours())
  var mins = Math.floor(durationValue.asMinutes()) - hours * 60
  var secs = Math.floor(durationValue.asSeconds()) - mins * 60
  const stringHours = hours >= 1 ? hours + 'jam ' : ''
  const stringMinutes = mins + 'menit '
  const stringSeconds = hours < 1 ? secs + 'detik ' : ''
  // console.log('asd', Math.floor(durationValue.asHours()) + moment.utc(durationValue.asMilliseconds()).format(':mm:ss'))
  return stringHours + stringMinutes + stringSeconds
}
